<template>
  <!-- <div><Toaster position="bottom-left" /></div> -->
</template>

<script setup lang="ts">
import { toast } from "vue-sonner";

import { defineComponent, h, markRaw } from "vue";
import { useI18n } from "#imports";

const { t } = useI18n();
const CustomDiv = defineComponent({
  setup() {
    return () =>
      h("div", {
        innerHTML: `${t(
          "components.cookieConsent.message"
        )} <a href='/privacy-policy' class="text-primary" title="${t(
          "components.cookieConsent.privacyPolicyTitle"
        )}">${t("components.cookieConsent.moreInfo")}</a>`,
      });
  },
});
const acceptCookies = () => {
  localStorage.setItem("cookieConsent", "true");
};

onMounted(() => {
  const consent = localStorage.getItem("cookieConsent");
  if (consent !== "true") {
    setTimeout(() => {
      toast(markRaw(CustomDiv), {
        duration: Infinity,
        action: {
          label: t("buttons.gotIt"),
          onClick: () => acceptCookies(),
        },
      });
    }, 30000);
  }
});
</script>

<style scoped></style>
